<template>
  <NuxtLink :to="to" :class="['internal-link', `internal-link-size-${size}`]">
    <SvgIcon
      v-if="isBackLink"
      icon="chevron-left"
      class="chevron-icon"
      aria-hidden="true"
    />
    <slot />
    <SvgIcon
      v-if="!isBackLink"
      icon="chevron-right"
      class="chevron-icon"
      aria-hidden="true"
    />
  </NuxtLink>
</template>

<script>
  export default {
    props: {
      to: {
        type: String,
        required: false,
        default: null,
      },
      isBackLink: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: "medium",
        validator: (value) => {
          return ["medium", "mini"].includes(value)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .internal-link {
    color: $navy-text;
    text-decoration: none;
    white-space: nowrap;
    &.internal-link-size-medium {
      @apply text-sm leading-normal;
    }
    &.internal-link-size-mini {
      @apply text-xs leading-normal;
    }
    .cs-dark & {
      color: $blue;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .chevron-icon {
    pointer-events: none;
    position: relative;
    top: -1px;
    vertical-align: middle;
    color: $navy-text;
    display: inline-block;
    width: 14px;
    height: 14px;
  }
</style>
